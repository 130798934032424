import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { styled } from '@mui/material/styles';
import {
  Drawer,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Stack,
  Grid,
  Typography,
  IconButton,
  CircularProgress
} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloseIcon from '@mui/icons-material/Close';
import { default as api } from "../../api/endpoints";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const NewFileDrawer = ({ isOpened, onClose }) => {
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = handleSubmit(({ name, file }) => {
    setIsSubmitting(true);
    api.adminFiles.upload({ name, file: file[0] }).then(() => {
      setIsSubmitting(false);
      reset();
      onClose({ refresh: true });
    });
  });

  const files = watch("file");

  return (
    <Drawer open={isOpened} onClose={() => null} anchor="right">
      <form onSubmit={onSubmit}>
        <Card sx={{ width: 700 }}>
          <CardHeader
            title="New file"
            action={
              <IconButton onClick={() => onClose({ refresh: false })}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  label="Name"
                  variant="outlined"
                  {...register("name", {
                    required: "Required",
                  })}
                  aria-label={"Name"}
                  fullWidth
                  error={errors.name}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={ (files && files.length > 0) ? <CloudDoneIcon /> : <CloudUploadIcon /> }
                  fullWidth
                >
                  { (files && files.length > 0) ? "Selected file" : "Select file" }

                  <VisuallyHiddenInput
                    type="file"
                    {...register("file", {
                      required: "Required",
                    })}
                  />
                </Button>

                { errors.file ? <Typography color="error" sx={{ fontSize: 12, marginLeft: 2, marginTop: 0.5 }}>{ errors.file.message }</Typography> : null }

                <Typography>
                  { (files && files.length > 0) ? files[0].name : null }
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    </Drawer>
  )
};

export default NewFileDrawer;
