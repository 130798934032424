import React, { useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Divider
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Layout from "./Layout";
import CompaniesStatisticsTable from "./EmailStatistics/CompaniesStatisticsTable";
import Controls from "./EmailStatistics/Controls";
import { default as api } from "../../api/endpoints";

const useStyles = makeStyles((theme) => ({
  cell: {
    border: "1px solid #ccc",
    padding: "1rem",
    width: "5rem"
  }
}));

const StatCell = ({ label, value, hoverValue }) => {
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();

  return (
    <Grid item onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}>
      <div className={classes.cell}>
        <Typography sx={{ fontSize: 14, textAlign: "center" }}>
          { label }
        </Typography>
        <Typography sx={{ fontSize: 32, textAlign: "center" }}>
          { isHovered && hoverValue ? hoverValue : value }
        </Typography>
      </div>
    </Grid>
  );
};


const EmailStatistics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({ selectedCompanyIds: [], period: "last_30d" });
  const [statistics, setStatistics] = useState(null);
  const [companyStatistics, setCompanyStatistics] = useState([]);

  const loadStatistics = ({ filters }) => {
    setIsLoading(true);

    api.emails.overallStatistics({ filters }).then(({ data }) => {
      setStatistics(data.overallStatistics);
      setCompanyStatistics(data.companyStatistics);
      setIsLoading(false);
    });
  };

  const handleChange = (changedFilters) => setFilters(changedFilters);

  const onSubmit = () => {
    loadStatistics({ filters });
  };

  useState(() => {
    api.emails.generalData().then(({ data: { companies } }) => {
      setCompanies(companies);

      const companyIds = companies.map(({ id }) => id);
      setFilters({ ...filters, selectedCompanyIds: companyIds });

      loadStatistics({ filters });
    });
  }, []);

  return (
    <Layout isLoading={isLoading}>
      {
        !isLoading ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Statistics" />
                <CardContent>
                  <Controls companies={companies} filters={filters} onChange={handleChange} onSubmit={onSubmit} />

                  <Divider sx={{ marginTop: 4, marginBottom: 4 }} />

                  <Grid container spacing={2} justifyContent="center">
                    <StatCell label="Deliveries" value={statistics.emailDeliveries} />
                    <StatCell label="Scheduled" value={statistics.scheduled} />
                    <StatCell label="Started" value={statistics.started} />
                    <StatCell label="Delivered" value={statistics.delivered.percent} hoverValue={statistics.delivered.absolute} />
                    <StatCell label="Opened" value={statistics.opened.percent} hoverValue={statistics.opened.absolute} />
                    <StatCell label="Clicked" value={statistics.clicked.percent} hoverValue={statistics.clicked.absolute} />
                  </Grid>

                  <CompaniesStatisticsTable companiesStatistics={ companyStatistics } />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : null
      }
    </Layout>
  )
};

export default EmailStatistics;
