import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  CircularProgress
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import PageContainer from "./PageContainer";
import { Loader } from "./Loader";
import { default as api } from "../api/endpoints";

const SalesSettings = () => {
  const { companyId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    handleSubmit,
    control,
    setValue
  } = useForm();

  useEffect(() => {
    api.companies.salesSettings({ companyId }).then(({ data }) => {
      setValue('allowCrossCompanyAccess', data.allow_cross_company_access);
      setIsLoading(false);
    });
  }, []);

  const onSubmit = handleSubmit(({ allowCrossCompanyAccess }) => {
    setIsSubmitting(true);
    api.companies.updateSalesSettings({ companyId, allowCrossCompanyAccess }).then(() => {
      setIsSubmitting(false);
    });
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <Card>
        <CardHeader title="Sales & Promotional Settings" />
        <CardContent>
          <Accordion expanded={false}>
            <AccordionSummary>
              <Controller
                control={control}
                name="allowCrossCompanyAccess"
                render={({ field: { onChange, value, ref } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        inputRef={ref}
                        color="primary"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    }
                    label={
                      <div>
                        <Typography>Allow Cross Company Access to All Quizzes</Typography>
                      </div>
                    }
                  />
                )}
              />
            </AccordionSummary>
          </Accordion>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </PageContainer>
  )
};

export default SalesSettings;
