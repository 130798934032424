import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Drawer
} from "@mui/material";
import PageContainer from "../PageContainer";
import Navigation from "./Navigation";
import SendEmail from "./SendEmail";
import { Loader } from "../Loader";

const PaperProps = {
  sx: {
    width: '75%'
  }
}

const Layout = ({ isLoading, children }) => {
  const [isSendEmailOpened, setIsSendEmailOpened] = useState(false);

  const handleSendEmailClick = () => setIsSendEmailOpened(true);
  const handleSendEmailClose = () => setIsSendEmailOpened(false);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Email Manager" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Navigation onSendEmailClick={handleSendEmailClick} />
                </Grid>
                <Grid item xs={12}>
                  { children }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Drawer anchor="right" open={isSendEmailOpened} PaperProps={PaperProps}>
        <SendEmail onClose={handleSendEmailClose} />
      </Drawer>
    </PageContainer>
  )
};

export default Layout;
