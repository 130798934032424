import { useState } from "react";
import { Button } from "@mui/material";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";

import Message from "./Message";
import { default as api } from "../../../api/endpoints";
import EditTemplateModal from "./EditTemplateModal";
import CreateTemplateModal from "./CreateTemplateModal";

const CreateOrUpdateTemplate = ({
  templateSelected,
  selectedQuestions,
  questionsLoading,
  canSaveTemplates,
  setTemplateSelected,
  templates,
  setTemplates,
  ...rest
}) => {
  const [isUpdatingTemplate, setIsUpdatingTemplate] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [showSaveDialog, setShowSaveDialog] = useState();

  const templateIds = templateSelected?.questionIds?.join("");
  const selectedQuestionIds = selectedQuestions?.map((q) => q.id).join("");

  const renderSaveTemplate = () => {
    if (questionsLoading || !canSaveTemplates) {
      return null;
    } else if (templateIds?.length > 0 && templateIds !== selectedQuestionIds) {
      return (
        <Button
          variant="contained"
          color="primary"
          type="button"
          size="large"
          onClick={() => setShowSaveDialog("update")}
          disabled={isUpdatingTemplate}
          sx={{ flexShrink: 0 }}
          startIcon={<LibraryAddIcon />}
        >
          Update Template
        </Button>
      );
    } else if (selectedQuestionIds?.length > 0 && !templateSelected) {
      return (
        <Button
          variant="contained"
          color="primary"
          type="button"
          size="large"
          sx={{ flexShrink: 0 }}
          onClick={() => setShowSaveDialog("create")}
          disabled={isUpdatingTemplate}
          startIcon={<LibraryAddIcon />}
        >
          Create Template
        </Button>
      );
    } else {
      return null;
    }
  };

  const handleUpdateSave = (values) => {
    const hasCustomQuestions = selectedQuestions.find((q) => q.custom_for);

    if (
      (!hasCustomQuestions ||
        window.confirm(
          "Are you sure you want to include custom questions in a template?"
        )) &&
      (values.questionIds.length > 0 ||
        window.confirm(
          "Are you sure you want to remove all questions from this template?"
        ))
    ) {
      setIsUpdatingTemplate(true);

      api.templates
        .update(values)
        .then((res) => {
          const updatedTemplate = res.data.template;
          setTemplateSelected(updatedTemplate);
          setTemplates(
            templates.map((t) =>
              t.id === updatedTemplate.id ? updatedTemplate : t
            )
          );
          setIsUpdatingTemplate(false);
          setShowSaveDialog(false);
          setSnackbarMessage(
            "Template Updated (Remember to Save The Quiz Separately)"
          );
        })
        .catch((err) => {
          window.alert("Something went wrong: ", err);
          setIsUpdatingTemplate(false);
        });
    }
  };

  const handleCreateSave = (values) => {
    const hasCustomQuestions = selectedQuestions.find((q) => q.custom_for);

    if (
      !hasCustomQuestions ||
      window.confirm(
        "Are you sure you want to include custom questions in a template?"
      )
    ) {
      setIsUpdatingTemplate(true);

      api.templates
        .create(values)
        .then((res) => {
          const createdTemplate = res.data.template;
          setTemplateSelected(createdTemplate);
          setTemplates([createdTemplate].concat(templates));
          setIsUpdatingTemplate(false);
          setShowSaveDialog(false);
          setSnackbarMessage(
            "Template Created (Remember to Save The Quiz Separately)"
          );
        })
        .catch((err) => {
          window.alert("Something went wrong: ", err);
          setIsUpdatingTemplate(false);
        });
    }
  };

  const renderModal = () => {
    if (showSaveDialog === "update") {
      return (
        <EditTemplateModal
          handleClose={() => setShowSaveDialog()}
          loading={isUpdatingTemplate}
          template={templateSelected}
          selectedQuestions={selectedQuestions}
          handleSave={handleUpdateSave}
          {...rest}
        />
      );
    }
    if (showSaveDialog === "create") {
      return (
        <CreateTemplateModal
          handleClose={() => setShowSaveDialog()}
          loading={isUpdatingTemplate}
          selectedQuestions={selectedQuestions}
          handleSave={handleCreateSave}
          {...rest}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {renderSaveTemplate()}
      {renderModal()}
      <Message
        open={!!snackbarMessage}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
    </>
  );
};

export default CreateOrUpdateTemplate;
