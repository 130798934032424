import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  IconButton
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PercentStatCell from "./PercentStatCell";
import EmailDeliveriesTable from "./EmailDeliveriesTable";

const CompanyRow = ({ companyStatistics: { company: { id, name }, scheduled, started, delivered, opened, clicked, emailDeliveries } }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <React.Fragment>
      <TableRow onClick={() => setIsOpened(!isOpened)}>
        <TableCell>{ name } (ID: { id })</TableCell>
        <TableCell>{ emailDeliveries.length }</TableCell>
        <TableCell>{ scheduled }</TableCell>
        <TableCell>{ started }</TableCell>
        <PercentStatCell stat={delivered} />
        <PercentStatCell stat={opened} />
        <PercentStatCell stat={clicked} />
        <TableCell>
          {
            emailDeliveries.length > 0 ? (
              <IconButton
                aria-label="expand row"
                size="small"
              >
                {isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : null
          }
        </TableCell>
      </TableRow>
      {
        emailDeliveries.length > 0 ? (
          <TableRow>
            <TableCell colSpan={7} sx={{ paddingBottom: 0, paddingTop: 0 }}>
              <Collapse in={isOpened} timeout="auto" unmountOnExit>
                <EmailDeliveriesTable emailDeliveries={emailDeliveries} />
              </Collapse>
            </TableCell>
          </TableRow>
        ) : null
      }
    </React.Fragment>
  )
}

const CompaniesStatisticsTable = ({ companiesStatistics }) => (
  <TableContainer sx={ { paddingTop: 3 } }>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Company</TableCell>
          <TableCell>Deliveries</TableCell>
          <TableCell>Scheduled</TableCell>
          <TableCell>Started</TableCell>
          <TableCell>Delivered</TableCell>
          <TableCell>Opened</TableCell>
          <TableCell>Clicked</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {
          companiesStatistics.map(
            (company) => (
              <CompanyRow companyStatistics={company} key={company.company.id} />
            )
          )
        }
      </TableBody>
    </Table>
  </TableContainer>
);

export default CompaniesStatisticsTable;
