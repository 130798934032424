import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Drawer,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Stack,
  TextField,
  Button,
  CircularProgress,
  IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { default as api } from "../../api/endpoints";

const EditFileDrawer = ({ file, isOpened, onClose }) => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (!isOpened || !file) {
      return ;
    }

    setValue("name", file.name)
  }, [isOpened, file])

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = handleSubmit(({ name }) => {
    setIsSubmitting(true);
    api.adminFiles.update({ id: file.id, name }).then(() => {
      setIsSubmitting(false);
      reset();
      onClose({ refresh: true });
    });
  });

  return (
    <Drawer open={isOpened} onClose={() => null} anchor="right">
      <form onSubmit={onSubmit}>
        <Card sx={{ width: 700 }}>
          <CardHeader
            title="Edit file"
            action={
              <IconButton onClick={() => onClose({ refresh: false })}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Stack spacing={2}>
              <TextField
                autoFocus
                label="Name"
                variant="outlined"
                {...register("name", {
                  required: "Required",
                })}
                aria-label={"Name"}
                fullWidth
                error={errors.name}
                helperText={errors.name?.message}
              />
            </Stack>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    </Drawer>
  )
};

export default EditFileDrawer;
