import React, { useState } from "react";
import {
  TableCell
} from "@mui/material";

const PercentStatCell = ({ stat: { absolute, percent } }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  return <TableCell onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{ isHovered ? absolute : percent }</TableCell>;
}

export default PercentStatCell;
