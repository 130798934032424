import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Stack,
  TextField,
  CardContent,
  CardActions,
  Button
} from "@mui/material";
import { default as api } from "../../api/endpoints";
import LoadingButton from "../LoadingButton";

const Editor = ({ company }) => {
  const [isSending, setIsSending] = useState(false);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      epilogue: company.endScreens.epilogue
    },
  });

  const redirectToCompanyDashboard = () => window.top.location.href = `${rootPath}/my_dashboard/${company.id}`;

  const onSubmit = handleSubmit((values) => {
    setIsSending(true);
    api.companies.updateEndScreens({ id: company.id, endScreens: values }).then(() => {
      setIsSending(false)
      redirectToCompanyDashboard();
    })
  })

  const onCancel = () => {
    redirectToCompanyDashboard();
  }

  const rootPath = process.env.REACT_APP_MAIN_ROOT;

  return (
    <form onSubmit={onSubmit}>
      <CardContent>
        <Stack spacing={3}>
          <TextField
            autoFocus
            name="epilogue"
            label="Epilogue"
            variant="outlined"
            {...register("epilogue")}
            aria-label={"Epilogue"}
            fullWidth={true}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <LoadingButton type="submit" loading={isSending}>
          Save
        </LoadingButton>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </CardActions>
    </form>
  );
};

export default Editor;
