import React, { useState, useMemo } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  Link,
  TableSortLabel,
  TableFooter,
  TablePagination
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";

const formatTime = (time) => moment(time).format("LLL");

const FileRow = ({ file, onEdit, onDelete }) => {
  const handleClick = () => { navigator.clipboard.writeText(public_url) };
  const { name, public_url, created_at } = file;

  return (
    <TableRow>
      <TableCell>
        { name }
      </TableCell>
      <TableCell width="50%">
        <Grid container spacing={1} sx={{ flexWrap: "nowrap", alignItems: "center" }}>
          <Grid item>
            <IconButton onClick={ handleClick }>
              <ContentCopyIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Link href={ public_url } target="_blank">
              { public_url }
            </Link>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        { formatTime(created_at) }
      </TableCell>
      <TableCell>
        <Grid container>
          <Grid item>
            <IconButton onClick={ () => onEdit(file) }>
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={ () => onDelete(file) }>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
};

const directionBy = ({ order, field }) => {
  if (field === order.field && order.direction === 'asc') {
    return 'desc';
  } else {
    return 'asc';
  }
}

const TableHeader = ({ order, onChange }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <TableSortLabel
            active={order.field === 'name'}
            direction={ order.field === 'name' ? order.direction : 'asc' }
            onClick={() => onChange({
              field: 'name',
              direction: directionBy({ order, field: 'name' })
            })}
          >
            Name
          </TableSortLabel>
        </TableCell>
        <TableCell>Link</TableCell>
        <TableCell>
          <TableSortLabel
            active={order.field === 'created_at'}
            direction={ order.field === 'created_at' ? order.direction : 'asc' }
            onClick={() => onChange({
              field: 'created_at',
              direction: directionBy({ order, field: 'created_at' })
            })}
          >
            Created at
          </TableSortLabel>
        </TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  )
};

const EmptyRow = () => (
  <TableRow>
    <TableCell colSpan={4} align="center">
      No files yet!
    </TableCell>
  </TableRow>
);

const FilesTable = ({ files, onEdit, onDelete }) => {
  const [order, setOrder] = useState({
    field: 'created_at',
    direction: 'desc'
  });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const rows = useMemo(() => {
    const compare = (a, b) => {
      const directionFactor = order.direction === 'asc' ? 1 : -1;
      const field = order.field;

      if (a[field] === b[field]) {
        return 0;
      } else if (a[field] < b[field]) {
        return -1 * directionFactor
      } else {
        return directionFactor
      }
    }

    const sortedRows = [...files].sort(compare);

    return sortedRows.slice(page * perPage, (page + 1) * perPage)
  }, [files, order, page, perPage]);

  const handleOrderChange = (newOrder) => {
    setOrder(newOrder);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handlePerPageChange = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table>
        <TableHeader order={ order } onChange={ handleOrderChange }/>
        <TableBody>
          {
            rows.length > 0 ? (
              rows.map(
                (file) => (
                  <FileRow
                    key={file.id}
                    file={file}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                )
              )
            ) : <EmptyRow />
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              colSpan={4}
              count={files.length}
              page={page}
              rowsPerPage={perPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default FilesTable;
