import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button
} from "@mui/material";

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

const CompaniesSelect = ({ companies, selectedCompanyIds, onChange }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const renderValue = (selected) => {
    if (selected.length === companies.length) {
      return "All";
    }

    return `Companies: ${selected.length} / ${companies.length}`;
  };

  return (
    <FormControl sx={{ width: 300 }}>
      <InputLabel id="companies-select-label">Companies</InputLabel>
      <Select
        labelId="companies-select-label"
        multiple
        value={selectedCompanyIds}
        onChange={handleChange}
        input={<OutlinedInput label="Companies" />}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {
          companies.map(
            (company) => (
              <MenuItem key={company.label} value={company.id}>
                <Checkbox checked={selectedCompanyIds.indexOf(company.id) > -1} />
                <ListItemText primary={company.label} />
              </MenuItem>
            )
          )
        }
      </Select>
    </FormControl>
  )
};

export default CompaniesSelect;
