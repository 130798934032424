import {
  Select,
  ListSubheader,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Divider,
} from "@mui/material";

const TimeFrameFilter = ({ selectedTimeFrame, setSelectedTimeFrame }) => (
  <FormControl fullWidth>
    <InputLabel id="time-frame-select-label">By Time Range</InputLabel>
    <Select
      labelId="time-frame-select-label"
      sx={{ m: 1, width: 200 }}
      label="By Time Frame"
      id="time-frame-select"
      value={selectedTimeFrame}
      onChange={(event) => setSelectedTimeFrame(event.target.value)}
    >
      <ListSubheader
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[50],
          cursor: "help",
          userSelect: "none",
          p: 1,
        })}
      >
        <Typography fontWeight="bold" fontStyle="italic">
          When Quiz Was Started
        </Typography>
        <Typography fontStyle="italic">
          Show quizzes that have a current start date within the selected range.
          Note: quiz start dates may have changed since originally set.
        </Typography>
      </ListSubheader>

      <MenuItem value="quiz-all" sx={{ ml: 2 }}>
        All Quizzes
      </MenuItem>
      <MenuItem value="quiz-ttm" sx={{ ml: 2 }}>
        Quizzes TTM (Quizzes With Current Start Date Within Trailing 12 Months)
      </MenuItem>
      <MenuItem value="quiz-ytd" sx={{ ml: 2 }}>
        Quizzes YTD (Quizzes With Current Start Date This Year)
      </MenuItem>

      <ListSubheader
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[50],
          cursor: "help",
          userSelect: "none",
          p: 1,
        })}
      >
        <Typography fontWeight="bold" fontStyle="italic">
          When Users Completed Quizzes
        </Typography>
        <Typography fontStyle="italic">
          Show which users completed quizzes within the selected range. Note:
          this may be the first or any subsequent completion of the same quiz.
        </Typography>
      </ListSubheader>

      <MenuItem value="user-ttm" sx={{ ml: 2 }}>
        Users TTM (Completed Given Quizzes Within Trailing 12 Months)
      </MenuItem>
      <MenuItem value="user-ytd" sx={{ ml: 2 }}>
        Users YTD (Completed Given Quizzes This Year)
      </MenuItem>
    </Select>
  </FormControl>
);

export default TimeFrameFilter;
