import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  QuizEditor,
  Questions,
  QuizReport,
  CompanyEditor,
  DoctorCribsManager,
  PreventConsentEditor,
  Activity,
  CompaniesList,
  UserManager,
  UserManagerMassArchive,
  Report,
  TogoReport,
  SummaryDashboard,
  RequesterDashboard,
  ReviewerDashboard,
  SimilarQuestions,
  Authorization,
  Home,
  EmailRecipients,
  SendEmail,
  EmailStatistics,
  EmailDeliveries,
  EmailDelivery,
  Consent,
  PilotHome,
  PilotSignup,
  PilotCreateCompany,
  PilotPostPayment,
  QuestionLinks,
  QuizAIReview,
  FileExplorer,
  EndScreensEditor,
  SalesSettings,
} from "./components";

const AppContainer = () => (
  <Router>
    <Authorization>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/pilot/post-payment" component={PilotPostPayment} />
        <Route path="/pilot/create-company" component={PilotCreateCompany} />
        <Route path="/pilot/signup" component={PilotSignup} />
        <Route path="/pilot" component={PilotHome} />

        <Route path="/questions" component={Questions} />
        <Route path="/quizzes/new" component={QuizEditor} />
        <Route path="/quizzes/:id/edit" component={QuizEditor} />
        <Route path="/quizzes/:id/question-links" component={QuestionLinks} />
        <Route path="/quizzes/:id/ai" component={QuizAIReview} />
        <Route path="/quizzes/:id" component={QuizReport} />
        <Route path="/companies/:id/edit" component={CompanyEditor} />
        <Route
          path="/companies/:companyId/quizzes/new"
          component={QuizEditor}
        />
        <Route path="/companies" component={CompaniesList} />
        <Route path="/reports/:companyId/togo" component={TogoReport} />
        <Route path="/reports/:companyId/participation" component={Report} />
        <Route path="/prevent-consent" component={PreventConsentEditor} />
        <Route path="/doctor-cribs" component={DoctorCribsManager} />
        <Route path="/activity" component={Activity} />
        <Route
          path="/user-manager/archive"
          component={UserManagerMassArchive}
        />
        <Route path="/user-manager" component={UserManager} />
        <Route path="/summary-dashboard" component={SummaryDashboard} />
        <Route path="/similar-questions" component={SimilarQuestions} />
        <Route
          path="/question-review/reviewer-dashboard"
          component={ReviewerDashboard}
        />
        <Route
          path="/question-review/requester-dashboard"
          component={RequesterDashboard}
        />
        <Route path="/email-manager/send-email" component={SendEmail} />
        <Route path="/email-manager/recipients" component={EmailRecipients} />
        <Route
          path="/email-manager/deliveries/:companyId/:id"
          component={EmailDelivery}
        />
        <Route path="/email-manager/deliveries" component={EmailDeliveries} />
        <Route path="/email-manager" component={EmailStatistics} />
        <Route path="/consent" component={Consent} />
        <Route path="/file-explorer" component={FileExplorer} />
        <Route path="/end-screens/:companyId" component={EndScreensEditor} />
        <Route path="/sales-settings/:companyId" component={SalesSettings} />
      </Switch>
    </Authorization>
  </Router>
);

export default AppContainer;
