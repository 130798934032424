import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Autocomplete,
  TextField,
  Grid,
  Alert
} from "@mui/material";
import { Link } from "react-router-dom";
import { default as api } from "../../api/endpoints";
import { Loader } from "../Loader";
import Layout from "./Layout";
import CompanySelect from "../CompanySelect";

const RecipientRow = ({ recipient }) => (
  <TableRow>
    <TableCell>{recipient.email}</TableCell>
    <TableCell>{recipient.last_delivery_at}</TableCell>
    <TableCell>{recipient.last_status}</TableCell>
    <TableCell>{recipient.total_started}</TableCell>
    <TableCell>{recipient.total_delivered}</TableCell>
    <TableCell>{recipient.total_opened}</TableCell>
    <TableCell>{recipient.total_clicked}</TableCell>
    <TableCell>{recipient.total_failed}</TableCell>
  </TableRow>
);

const RecipientsTable = ({ recipients, selectedCompany }) => {
  const ROWS_PER_PAGE = 100;
  const [page, setPage] = useState(0);

  const handleChangePage = (e, newPage) => setPage(newPage);

  if (selectedCompany === null) {
    return <Alert severity="info">Please select company</Alert>;
  }

  if (recipients.length === 0) {
    return <Alert severity="warning">No recipient yet</Alert>;
  }

  const visibleRecipients = recipients.slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email (total: {recipients.length})</TableCell>
              <TableCell>Last delivery at</TableCell>
              <TableCell>Last status</TableCell>
              <TableCell>Total started</TableCell>
              <TableCell>Total delivered</TableCell>
              <TableCell>Total opened</TableCell>
              <TableCell>Total clicked</TableCell>
              <TableCell>Total failed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRecipients.map((recipient) => (
              <RecipientRow recipient={recipient} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        count={recipients.length}
        rowsPerPage={ROWS_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
      />
    </>
  );
};

const EmailManager = () => {
  const [isLoading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [recipients, setRecipients] = useState([]);

  const refreshRecipients = (selectedCompany) => {
    if (!selectedCompany) {
      return;
    }

    setLoading(true);

    api.emails
      .recipients({ companyId: selectedCompany.id })
      .then(({ data }) => {
        setRecipients(data.email_recipients);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!selectedCompany) {
      return ;
    }

    refreshRecipients(selectedCompany);
  }, [selectedCompany]);

  const handleOpenImport = (e) => {
    e.preventDefault();
    setIsImportOpened(true);
  };

  const handleCloseImport = () => {
    setIsImportOpened(false);
  };

  const handleSelectedCompanyChange = (company) => {
    setSelectedCompany(company);
  };

  const handleDeliveriesRefreshRequest = () => {
    refreshRecipients(selectedCompany);
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Overall stats" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CompanySelect onChange={handleSelectedCompanyChange} value={selectedCompany} />
                </Grid>
                <Grid item xs={12}>
                  <RecipientsTable recipients={recipients} selectedCompany={selectedCompany} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default EmailManager;

