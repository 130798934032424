import { Button, Toolbar, AppBar, Stack, Alert } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import LoadingButton from "../../LoadingButton";

const Controls = ({
  isLoading,
  isDirty,
  editingMode,
  templateButton,
  onRevert,
}) => (
  <AppBar
    position="fixed"
    sx={{
      top: "auto",
      bottom: 0,
      backgroundColor: "#A3C8FF",
      boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.4)",
    }}
  >
    <Toolbar>
      <Stack direction="row" spacing={2}>
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={isLoading || !isDirty}
          loading={isLoading}
        >
          {editingMode ? "Save Quiz" : "Create Quiz"}
        </LoadingButton>
        {isDirty ? (
          <Button
            startIcon={<HistoryIcon />}
            variant="contained"
            color="primary"
            type="button"
            sx={{ flexShrink: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              onRevert();
            }}
          >
            Revert
          </Button>
        ) : null}

        {templateButton}

        {isDirty && (
          <Alert severity="warning" sx={{ flexShrink: 0 }}>
            Unsaved Changes
          </Alert>
        )}
      </Stack>
    </Toolbar>
  </AppBar>
);

export default Controls;
