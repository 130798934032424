import React from "react";
import {
  Grid,
  Button
} from "@mui/material";
import CompaniesSelect from "./CompaniesSelect";
import PeriodSelect from "./PeriodSelect";

const Controls = ({ companies, filters, onChange, onSubmit }) => {
  const handleSelectedCompanyIdsChange = (selectedCompanyIds) => onChange({ ...filters, selectedCompanyIds });
  const handlePeriodChange = (period) => onChange({ ...filters, period });

  return (
    <Grid container spacing={2} justifyContent="end">
      <Grid item>
        <CompaniesSelect
          companies={companies}
          selectedCompanyIds={filters.selectedCompanyIds}
          onChange={handleSelectedCompanyIdsChange}
        />
      </Grid>
      <Grid item>
        <PeriodSelect
          period={filters.period}
          onChange={handlePeriodChange}
        />
      </Grid>
      <Grid item sx={{ marginTop: 1 }}>
        <Button variant="contained" onClick={() => onSubmit()}>
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
};

export default Controls;
