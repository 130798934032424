import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const SortSelector = ({ sort, setSort }) => (
  <FormControl fullWidth>
    <InputLabel id="sort-select-label">Sort Quizzes</InputLabel>
    <Select
      labelId="sort-select-label"
      sx={{ m: 1, width: 200 }}
      id="sort-select"
      value={sort}
      onChange={(event) => setSort(event.target.value)}
    >
      <MenuItem value="reverse-chronological">Reverse Chronological</MenuItem>
      <MenuItem value="chronological">Chronological</MenuItem>
    </Select>
  </FormControl>
);

export default SortSelector;
