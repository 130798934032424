import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import moment from "moment";
import PercentStatCell from "./PercentStatCell";

const EmailDeliveryRow = ({ emailDeliveryStatistics: { emailDelivery: { id, type, startedAt }, scheduled, started, delivered, opened, clicked } }) => (
  <TableRow>
    <TableCell>{ id }</TableCell>
    <TableCell>{ type }</TableCell>
    <TableCell>{ startedAt ? moment(startedAt).format("LLL") : null }</TableCell>
    <TableCell>{ scheduled }</TableCell>
    <TableCell>{ started }</TableCell>
    <PercentStatCell stat={delivered} />
    <PercentStatCell stat={opened} />
    <PercentStatCell stat={clicked} />
  </TableRow>
);

const EmailDeliveriesTable = ({ emailDeliveries }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Started at</TableCell>
          <TableCell>Scheduled</TableCell>
          <TableCell>Started</TableCell>
          <TableCell>Delivered</TableCell>
          <TableCell>Opened</TableCell>
          <TableCell>Clicked</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          emailDeliveries.map(
            (emailDelivery) => <EmailDeliveryRow emailDeliveryStatistics={emailDelivery} key={emailDelivery.emailDelivery.id} />
          )
        }
      </TableBody>
    </Table>
  </TableContainer>
);

export default EmailDeliveriesTable;
