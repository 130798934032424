import React from "react";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Tooltip,
  TableFooter,
  Box,
} from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import theme from "../../mui-theme";

import { htmlToText } from "html-to-text";

const getWarning = (list) => {
  const keys = Object.keys(list);

  return keys
    .map(
      (key) =>
        `- ${key}: ${list[key]
          .map(
            ({ id, name, start_date }) =>
              `[ID#${id}] ${name}${
                start_date ? ` (${moment(start_date).calendar()})` : ""
              }`
          )
          .join(", ")}`
    )
    .join("\n");
};

const DnDQuestionsList = React.memo(function QuestionsList({
  questions,
  onRemove,
  onDragEnd,
  setEditQuestion,
  repeatQuestionsWarning,
}) {
  if (!questions.length) return null;

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list" style={{ display: "table" }}>
          {(provided) => (
            <Table
              size="small"
              ref={provided.innerRef}
              sx={{ maxWidth: "100%" }}
            >
              <TableHead>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell>
                    <ImportExportIcon />
                  </TableCell>
                  <TableCell sx={{ fontSize: 12 }}>Actions</TableCell>

                  <TableCell sx={{ fontSize: 12 }}>ID</TableCell>
                  <TableCell sx={{ minWidth: 125, fontSize: 12 }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ fontSize: 12 }}>Custom</TableCell>
                  <TableCell sx={{ fontSize: 12 }}>Published</TableCell>
                  <TableCell sx={{ fontSize: 12 }}>Harvard Approved</TableCell>
                  <TableCell sx={{ fontSize: 12 }}>Module</TableCell>
                  <TableCell sx={{ fontSize: 12 }}>Format</TableCell>
                  <TableCell sx={{ fontSize: 12 }}>Quizzes Using</TableCell>
                  <TableCell sx={{ fontSize: 12 }}>Last Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ width: "100%" }}>
                {questions.map((q, i) => {
                  return (
                    <Draggable
                      draggableId={q.id.toString()}
                      key={`question-${q.id}`}
                      index={i}
                    >
                      {(provided) => (
                        <>
                          <TableRow
                            key={q.id}
                            hover
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <TableCell {...provided.dragHandleProps}>
                              <DragHandleIcon />
                            </TableCell>
                            <TableCell sx={{ minWidth: 115 }}>
                              <Tooltip title="Preview" aria-label="preview">
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(
                                      `${process.env.REACT_APP_GAME_APP_URL}/quiz?embed=true&questions=${q.id}`
                                    );
                                  }}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit" aria-label="edit">
                                <IconButton
                                  type="button"
                                  onClick={(e) => {
                                    setEditQuestion(q);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Remove from Quiz"
                                aria-label="remove question"
                              >
                                <IconButton
                                  type="button"
                                  onClick={(e) => {
                                    onRemove(q);
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell sx={{ fontSize: 12 }}>{q.id}</TableCell>

                            <TableCell sx={{ fontSize: 12 }}>
                              {htmlToText(q.prompt)}

                              {repeatQuestionsWarning[q.id] ? (
                                <>
                                  <br /> <br />
                                  <Typography color="error">
                                    {q.id} also appears in:
                                    <br />
                                    {getWarning(repeatQuestionsWarning[q.id])}
                                  </Typography>
                                </>
                              ) : null}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: theme.palette.error.light,
                                fontSize: 12,
                              }}
                            >
                              {q.custom_for}
                            </TableCell>

                            <TableCell align="center">
                              {q.published ? (
                                <CheckCircleIcon color="primary" />
                              ) : null}
                            </TableCell>
                            <TableCell align="center">
                              {q.harvard_approved ? (
                                <CheckCircleIcon
                                  style={{
                                    color: "#A41034",
                                  }}
                                />
                              ) : null}
                            </TableCell>
                            <TableCell sx={{ fontSize: 12 }}>
                              {q.learning_module}
                            </TableCell>
                            <TableCell sx={{ fontSize: 12 }}>
                              {q.format}
                            </TableCell>
                            <TableCell sx={{ fontSize: 12 }}>
                              {q.num_quizzes}
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {moment(q.updated_at).calendar()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </Draggable>
                  );
                })}
              </TableBody>
              <TableFooter>{provided.placeholder}</TableFooter>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
});

export default DnDQuestionsList;
