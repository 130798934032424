import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Paper,
  IconButton,
  Link,
  Grid
} from "@mui/material";
import UploadIcon from '@mui/icons-material/Upload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NewFileDrawer from "./NewFileDrawer";
import EditFileDrawer from "./EditFileDrawer";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import FilesTable from "./FilesTable";
import PageContainer from "../PageContainer";
import { Loader } from "../Loader";
import { default as api } from "../../api/endpoints";

const FileExplorer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [isNewFileDrawerOpened, setIsNewFileDrawerOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDeleteConfirmationOpened, setIsDeleteConfirmationOpened] = useState(false);
  const [isEditFileDrawerOpened, setIsEditFileDrawerOpened] = useState(false);

  useEffect(() => {
    api.adminFiles.list().then(({ data }) => {
      setFiles(data.admin_files);
      setIsLoading(false);
    });
  }, []);

  const refreshList = () => {
    setIsLoading(true);
    api.adminFiles.list().then(({ data }) => {
      setFiles(data.admin_files);
      setIsLoading(false);
    });
  }

  const handleNewFileDrawerClose = ({ refresh }) => {
    setIsNewFileDrawerOpened(false);

    if (refresh) {
      refreshList();
    }
  };

  const handleDelete = (file) => {
    setSelectedFile(file);
    setIsDeleteConfirmationOpened(true);
  };

  const handleDeleteConfirmationDialogClose = () => {
    setSelectedFile(null);
    setIsDeleteConfirmationOpened(false);
  };

  const handleEdit = (file) => {
    setSelectedFile(file);
    setIsEditFileDrawerOpened(true);
  }

  const handleEditFileDrawerClose = ({ refresh }) => {
    setIsEditFileDrawerOpened(false);

    if (refresh) {
      refreshList();
    }
  }

  const handleDeleteConfirm = () => {
    api.adminFiles.remove({ id: selectedFile.id }).then(() => {
      refreshList();
      setSelectedFile(null);
      setIsDeleteConfirmationOpened(false);
    }).catch((error) => {
      alert(`Cannot delete file because of: ${error.message}`);
    });
  };

  return (
    <PageContainer>
      <Paper>
        <Card>
          <CardHeader
            title="Files explorer"
            action={
              <IconButton aria-label="settings" onClick={() => setIsNewFileDrawerOpened(true)}>
                <UploadIcon fontSize="large" />
              </IconButton>
            }
          />
          <CardContent>
            { isLoading ? <Loader /> : <FilesTable files={files} onEdit={handleEdit} onDelete={handleDelete} /> }
          </CardContent>
        </Card>
      </Paper>

      <NewFileDrawer
        isOpened={isNewFileDrawerOpened}
        onClose={handleNewFileDrawerClose}
      />

      <EditFileDrawer
        isOpened={isEditFileDrawerOpened}
        onClose={handleEditFileDrawerClose}
        file={selectedFile}
      />

      <DeleteConfirmationDialog
        isOpened={isDeleteConfirmationOpened}
        onClose={handleDeleteConfirmationDialogClose}
        onConfirm={handleDeleteConfirm}
        name={selectedFile?.name}
      />
    </PageContainer>
  )
}

export default FileExplorer;
