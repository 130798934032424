import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button
} from "@mui/material";

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

const PERIODS = [
  { value: "last_7d", label: "Last 7 days" },
  { value: "last_30d", label: "Last 30 days" },
  { value: "last_365d", label: "Last 365 days" },
  { value: "all", label: "No limit" }
];

const PeriodSelect = ({ period, onChange }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const renderValue = (selected) => {
    return `Companies: ${selected.length} / ${companies.length}`;
  };

  return (
    <FormControl sx={{ width: 300 }}>
      <InputLabel id="companies-select-label">Period</InputLabel>
      <Select
        labelId="companies-select-label"
        value={period}
        onChange={handleChange}
        label="Period"
        MenuProps={MenuProps}
      >
        {
          PERIODS.map(
            (periodOption) => (
              <MenuItem key={periodOption.value} value={periodOption.value}>
                { periodOption.label }
              </MenuItem>
            )
          )
        }
      </Select>
    </FormControl>
  )
};

export default PeriodSelect;
