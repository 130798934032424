import React, { useState } from "react";
import {
  Grid,
  Box,
  Tabs,
  Tab,
  Button
} from "@mui/material";
import { Link, useRouteMatch } from "react-router-dom";

const LinkTab = ({ label, to }) => <Tab component={Link} to={to} label={label} />;

const Navigation = ({ onSendEmailClick }) => {
  const { path } = useRouteMatch();
  const links = [
    {
      path: "/email-manager",
      label: "Statistics"
    },
    {
      path: "/email-manager/deliveries",
      label: "Deliveries"
    },
    {
      path: "/email-manager/recipients",
      label: "Recipients"
    }
  ]

  const value = links.findIndex((link) => link.path === path) || 0;
  const handleSendEmailClick = (e) => {
    e.stopPropagation();

    onSendEmailClick();
  }

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value}>
            {
              links.map(
                ({ path, label }) => (
                  <LinkTab to={path} label={label} key={path} />
                )
              )
            }
          </Tabs>
        </Box>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" sx={{ marginTop: 1 }} onClick={handleSendEmailClick}>
          Send email
        </Button>
      </Grid>
    </Grid>
  )
};

export default Navigation;
