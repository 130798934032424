import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import api from "../api/endpoints";
import { CircularProgress } from "@mui/material";

const QuizAIReview = () => {
  const [corrections, setCorrections] = useState("");
  const { id: quizId } = useParams();
  const intervalRef = useRef(null);

  useEffect(() => {
    const pollCorrections = () => {
      api.spellCheck
        .checkQuiz(quizId)
        .then((response) => {
          if (response.data.status === "complete") {
            setCorrections(response.data.corrections);
            clearInterval(intervalRef.current);
          } else {
            console.log("Review " + response.data.status);
          }
        })
        .catch((error) => {
          window.alert("There was an error, please reload page: ", error);
          clearInterval(intervalRef.current);
        });
    };

    intervalRef.current = setInterval(pollCorrections, 5000);
    pollCorrections();

    return () => clearInterval(intervalRef.current);
  }, [quizId]);

  return (
    <div>
      <h1>Quiz Review</h1>
      {corrections ? (
        <p style={{ whiteSpace: "pre-wrap" }}>{corrections}</p>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default QuizAIReview;
