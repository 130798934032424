import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Divider
} from "@mui/material";
import { useParams } from "react-router-dom";
import PageContainer from "../PageContainer";
import { Loader } from "../Loader";
import { default as api } from "../../api/endpoints";
import Editor from "./Editor";

const EndScreensEditor = () => {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const { companyId } = useParams();

  useEffect(() => {
    api.companies.endScreens({ id: companyId }).then(({ data }) => {
      setCompany(data.company);
      setLoading(false);
    });
  }, [companyId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <Card>
        <CardHeader title={ `${company.name}: end screens settings` } />
        <Divider />
        <Editor company={company} />
      </Card>
    </PageContainer>
  )
};

export default EndScreensEditor;
