import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box
} from "@mui/material";

const DeleteConfirmationDialog = ({ isOpened, onConfirm, onClose, name }) => {
  if (!isOpened) {
    return null;
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
    >
      <DialogTitle>
        Deletion confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to delete <Box component="span" sx={{ fontWeight: "bold" }}>{ name }</Box>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>No</Button>
        <Button onClick={onConfirm} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default DeleteConfirmationDialog;
