import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Accordion,
  AccordionSummary,
  Switch,
  FormControlLabel,
  Typography
} from "@mui/material";
import { Controller } from "react-hook-form";

const SalesSettings = ({ register, control }) => {
  return (
    <Card>
      <CardHeader title="Sales & Promotional Settings" />
      <Divider />
      <CardContent>
        <Accordion expanded={false}>
          <AccordionSummary>
            <Controller
              control={control}
              name="allowCrossCompanyAccess"
              render={({ field: { onChange, value, ref } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      inputRef={ref}
                      color="primary"
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label={
                    <div>
                      <Typography>Allow Cross Company Access</Typography>
                      <Typography variant="caption">
                        (It can be overriden by company-level settings)
                      </Typography>
                    </div>
                  }
                />
              )}
            />
          </AccordionSummary>
        </Accordion>

        <Accordion expanded={false}>
          <AccordionSummary>
            <Controller
              control={control}
              name="showTableOfContents"
              render={({ field: { onChange, value, ref } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      inputRef={ref}
                      color="primary"
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label={
                    <div>
                      <Typography>Show Table of Contents</Typography>
                    </div>
                  }
                />
              )}
            />
          </AccordionSummary>
        </Accordion>
      </CardContent>
    </Card>
  )
};

export default SalesSettings;
